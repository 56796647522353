import React, {useState} from 'react';
import './App.css';
import {RiSignalTowerLine} from "react-icons/ri";
import {FiLoader} from "react-icons/fi";
import {FaMobileAlt} from "react-icons/fa";

var moment = require('moment'); // require

const Tile = props => {
    const pStyle = {
        display: 'flex',
        justifyContent: 'space-between'
    }

    return <div
        className={'tiles'}>
        <div style={{marginBottom: '0.5rem'}}>{props.mac?.substr(0, 6)}</div>
        <div>{moment(props.now).fromNow()}</div>
        <div style={pStyle}><RiSignalTowerLine/> {props.rssi ?? '?'}</div>
    </div>
}

function App() {
    const [mijaResponses, setMijaResponses] = useState({})
    const [errorMessage, setErrorMessage] = useState("Click that Icon")
    const [isScanning, setIsScanning] = useState(false);
    const [scanHandle, setScanHandle] = useState(null);

    return (
        <div className="App">
            <header className="App-header">
                <span onClick={async () => {
                    if (isScanning) {
                        if (scanHandle !== null) {
                            scanHandle.stop();
                            setErrorMessage("Bluetooth-LE scan stopped.");
                            setScanHandle(null);
                        }
                        setIsScanning(false)
                        return;
                    }


                    if (navigator.bluetooth === undefined) {
                        setErrorMessage("Bluetooth API not available");
                    }

                    const options = {acceptAllAdvertisements: true};
                    let scan
                    try {
                        navigator.bluetooth.requestLEScan(options)
                        scan = await navigator.bluetooth.requestLEScan(options);
                    } catch (e) {
                        setErrorMessage(e.message);
                        setScanHandle(null);
                        return;
                    }
                    console.log(scan)
                    setScanHandle(scan);
                    setIsScanning(true);
                    setErrorMessage("Scan...");

                    navigator.bluetooth.addEventListener('advertisementreceived', event => {
                        const isCwa = (event.uuids ?? []).find(e => {
                            return e.startsWith('0000fd6f')
                        }) !== undefined;
                        if (!isCwa) {
                            return;
                        }
                        const deviceID = event.device.id;
                        setMijaResponses(prevState => {
                            return {
                                ...prevState,
                                [deviceID]: {
                                    rssi: event.rssi,
                                    mac: deviceID,
                                    now: new Date()

                                }
                            }
                        })
                    });
                }}>
                <FiLoader size={'4rem'}
                          className={isScanning ? 'isScanning' : ''}


                /><span style={{fontSize: '4rem', marginLeft: '3rem'}}>{Object.values(mijaResponses).length}</span>
                    <FaMobileAlt size={'1.2rem'}/>
                </span>
                <p>{errorMessage}</p>

                <div style={{
                    flexWrap: 'wrap', display: 'flex'
                }}>
                    {Object.values(mijaResponses).sort((a, b) => b.now - a.now).map(response => {
                        return (<Tile {...response} />)
                    })}
                </div>

            </header>
        </div>
    );
}

export default App;
